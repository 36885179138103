:root {
	/* --pane-title-color: #aaa; */
	--main-text-color: #ccc;
	--pane-title-bgcolor: #222;
	--pane-body-bgcolor_1: #333;
	--pane-body-bgcolor_2: #222;
	--indicator-color: rgba(255, 255, 255, 0.5);
	--indicator-outline-color: rgba(30, 30, 30, 0.5);
	--active-color: #f55;
}

body {
	background-image: url("./images/bg.jpg");
	background-color: #333 !important;
	/*background-image: radial-gradient(#666, #455);  */
	/* color: var(--main-text-color); */
	background-size: cover;
	/* background-color: #19212e; */

	-webkit-box-shadow: 14px 10px 413px 3px rgba(0, 0, 0, 0.6);
	-moz-box-shadow: 14px 10px 43px 3px rgba(0, 0, 0, 0.6);
	box-shadow: 14px 10px 43px 3px rgba(0, 0, 0, 0.6);
}

.clickable {
	cursor: pointer;
}

.loadmask {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: url("./images/spinner.gif") center no-repeat #000;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		visibility: hidden;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		visibility: hidden;
	}
}

.fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

.bpm-info {
	/* border: 10px solid red; */
	background-color: #343a40;
	/* -webkit-animation-duration: 1s;
	animation-duration: 1s; */
}

@keyframes bump {
	0% {
		background-color: var(--active-color);
	}
	100% {
		background-color: #343a40;
	}
}

@-webkit-keyframes bump {
	0% {
		background-color: var(--active-color);
	}
	100% {
		background-color: #343a40;
	}
}

.bump {
	-webkit-animation-name: bump;
	animation-name: bump;
}

.App {
	text-align: center;
	color: #ccc;
	/* visibility: hidde; */
}

.App .rc-slider-handle {
	border: solid 2px #ff5555;
	background-color: #fff;
}

.App .rc-slider-handle:hover {
	border-color: #ffaaaa;
}

.App .rc-slider-track {
	background-color: rgb(255, 85, 85);
}

.advancedSlider .inlineBtn {
	font-size: 0.7em;
}

.App .pane {
	/* background-color: #222; */
	/* border: thin solid #fff; */
	background-image: linear-gradient(
		var(--pane-body-bgcolor_1),
		var(--pane-body-bgcolor_2)
	);
	-webkit-box-shadow: 10px 10px 30px 3px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 10px 10px 30px 3px rgba(0, 0, 0, 0.15);
	box-shadow: 10px 10px 30px 3px rgba(0, 0, 0, 0.15);
}

.App .app-container {
	margin-top: 1rem;
	/* margin: 0 0 !important; */
}

.App .container {
	/* padding: 0px; */
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	border-radius: 5px;
	/* padding-top: 10px; */
}

.App .pane .pane-title {
	text-transform: uppercase;
	padding: 0 1rem;
	/* margin-bottom: 0.5rem; */
	color: var(--pane-title-color);
	background-color: var(--pane-title-bgcolor);
	text-align: left;
	border-radius: 5px 5px 0 0;
}

.App .pane-body {
	/* padding: 0 1rem; */
	display: block;
	padding: 1rem;
}

.App .bpm-info {
	padding: 0.3rem;
	margin: 0.5rem;
	/* -webkit-transition: padding 0.1s ease-out;
	-moz-transition: margin 0.2s ease-out;
	-o-transition: margin 0.2s ease-out; */
}

/* .App .bump {
	color: red;
	padding: 0.8rem;
} */

.input-group-text {
	min-width: 4em;
}
.App .rc-slider-dot-active {
	border-color: #f55;
}

.App .rc-slider-mark-text-active {
	color: #fee;
}
.App .rc-slider-handle:hover {
	border-color: #a55;
}

.App .rc-slider-handle-click-focused:focus {
	border-color: #f55;
}

.App .badge-light {
	color: var(--main-text-color);
	font-weight: lighter;
	border: 0px solid #ccc;
	background-color: transparent;
}

.Planner {
	font-size: 80%;
	/* background-color: #777; */
}

.Planner .locked {
	background-color: #6c757d;
}

.step {
	background-color: #90a1b128;
	margin-bottom: 1px;
}

.Planner .current-step {
	background-color: #f55;
}

.SimpleProgress .bar {
	padding: 1px;
	background-color: #f55;
}

.SimpleVis {
	position: relative;
}

.SimpleVis div {
	/* width: 2rem; */
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	font-size: 1rem;
	text-align: center;
	/* border: 3px solid; */
	border-radius: 50%;
	/* background-color: #f55; */
}

.SimpleVis .active {
	/* border: 3px solid #f55; */
	background-color: #f55;
	color: #555;
	/* text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073; */
}

.PresetsManager {
	font-size: 80%;
}

.PresetsManager .presetItem {
	background-color: #90a1b128;
	margin-bottom: 1px;
}

.PresetsManager .presetItem .userPresetSavedDtm {
	color: #fff4;
	padding: 0 1em;
}
/* .PresetsManager .preset {
    cursor: pointer;
    box-shadow: 1px 0 0 0 #888, 0 1px 0 0 #888, 1px 1px 0 0 #888, 1px 0 0 0 #888 inset, 0 1px 0 0 #888 inset;
} */

.PresetsManager .editBtn {
	color: #f5f5f5;
	background-color: #f55;
	padding: 0 1em;
	margin-left: 1em;
}

.Accent {
	display: inline-block;
	background-color: transparent;
	min-width: 1.5rem;
	height: 2.2rem;
	border: 1px solid #f55;
	border-radius: 5px;
	margin-right: 1px;
	cursor: pointer;
}

.Accent .type1 {
	border-radius: 4px 4px 0 0;
	position: relative;
	height: 0.7rem;
	top: -0.1rem;
	background-color: #f55;
}
.Accent .type2 {
	height: 0.7rem;
	position: relative;
	top: 0.69rem;
	background-color: #f75;
}
.Accent .type0 {
	height: 0.7rem;
	position: relative;
	top: 1.39rem;
	background-color: #f95;
	border-radius: 0 0 4px 4px;
}

.Accent.active {
	border-color: rgba(221, 221, 221, 0.733);
	box-shadow: 0px 0px 2px 3px rgba(221, 221, 221, 0.733);
	-webkit-box-shadow: 0px 0px 2px 3px rgba(221, 221, 221, 0.733);
	-moz-box-shadow: 0px 0px 2px 3px rgb(221, 141, 141);
}

.modal-body .code {
	font-family: "Courier New", Courier, monospace;
	word-break: break-all;
	font-size: 60%;
	background-color: #ccc;
	padding: 1rem;
	margin-top: 1rem;
}

.about .pane-body {
	font-size: 1em;
}

.about a {
	color: #f55;
}

.footer {
	font-size: 0.7em;
	color: #777;
}
.footer .badge {
	background-color: transparent;
	font-weight: normal;
}

.about code {
	font-size: 15px;
	color: #f55;
}

.svgText {
	font-size: 1%;
	fill: var(--main-text-color);
}

.trackView {
	display: flex;
	flex-wrap: nowrap;
}

.trackView .trackContainer {
	margin: auto;
	padding: 0 0.5em;
	/* align-self: center; */
	width: 100%;
}

.trackView .btnContainer {
	margin: auto;
}

.trackView .beatsCntBadge {
	font-size: x-large;
}

.trackView .btn-xs,
.btn-group-xs > .btn {
	padding: 0.15rem 0.35rem;
	font-size: 0.575rem;
	line-height: 1;
	border-radius: 0.1rem;
	/* margin-left: 0.1rem; */
}

.trackRow {
	display: flex;
	margin-bottom: 10px;
}

.trackRow .label {
	display: flex;
}
.trackRow .cells {
	display: flex;
	width: 100%;
}

.trackView .indicator {
	width: 0.3rem;
	border-radius: 2px;
	background-color: var(--indicator-color);
	border: 1px solid var(--indicator-outline-color);
	position: absolute;
}

.trackRow .cell {
	min-width: 0.5em;
	min-height: 2em;
	margin: auto;
	flex-grow: 1;
	border: 0.1em solid #a55;
	margin: 0.1em;
	border-radius: 0.2em;
}

.presetItem .btn {
	line-height: 0.8;
}

.presetItem .col {
	padding: 0;
}
